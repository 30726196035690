<template>
  <div style="height: 100%">
    <v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters justify="end">
      <v-col class="text-right" cols="12">
        <v-btn class="inbox-tab-mobile" :color="selectedTab == 'inbox' ? 'primary' : ''" depressed
               @click="onChangeTab('inbox')">{{ $t(`button.inbox`) }}
        </v-btn>
        <v-btn class="inbox-tab-mobile" :color="selectedTab == 'announcement' ? 'primary' : ''" depressed
               @click="onChangeTab('announcement')">{{ $t(`button.notification`) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card style="height: 100%" class=""
            :class="$vuetify.breakpoint.mdAndUp ? 'desktop_inbox_card ml-4' : 'desktop_card'">
      <v-card-title class="justify-space-between">
        <label class="--v-primary-base text-left" :class="$vuetify.breakpoint.mdAndUp ? 'ml-4 inbox-desktop-txt' : ''">{{ $t(`label.inbox`)
          }}</label>
        <v-btn v-if="$vuetify.breakpoint.mdAndDown" icon elevation="0" class="icon-btn mt=0 mr-2" @click="deleteMsg()">
          <delete-icon></delete-icon>
        </v-btn>
      </v-card-title>
      <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters justify="end" class="px-10">
        <v-col class="text-left" cols="12">
          <v-btn class="tab-btn"
                 :class="{ 'selected-tab': selectedTab === 'inbox', 'not-selected-tab': selectedTab !== 'inbox' }"
                 depressed @click="onChangeTab('inbox')">
            {{ $t("button.inbox") }}
          </v-btn>
          <v-btn class="tab-btn"
                 :class="{ 'selected-tab': selectedTab === 'announcement', 'not-selected-tab': selectedTab !== 'announcement' }"
                 depressed @click="onChangeTab('announcement')">
            {{ $t("button.notification") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters align="end" class="hidden-sm-and-down pt-6 pb-3 voucher-border">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!--      <v-row no-gutters align="end" class="hidden-sm-and-down py-3">-->
      <!--        <v-col cols="12" class="d-flex">-->
      <!--          <v-col>-->
      <!--            <v-checkbox class="mt-0"></v-checkbox>-->
      <!--            <label class="field-name">{{ $t(`field.dateOfBirth`) }}</label>-->
      <!--            <v-btn v-if="$vuetify.breakpoint.mdAndDown" icon elevation="0" class="icon-btn mt=0 mr-2" @click="deleteMsg()">-->
      <!--              <delete-icon></delete-icon>-->
      <!--            </v-btn>-->
      <!--          </v-col>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <v-card class="inbox-card profile-detail-card" :class="$vuetify.breakpoint.mdAndDown ? 'profile-detail-card' : ''"
              elevation="0">
        <v-row v-if="$vuetify.breakpoint.lgAndUp" class="ma-1 inbox-details-header">
          <v-col cols="1" class="pa-0">
            <v-checkbox class="my-1 ml-2" v-model="selectAll" @click="selectAllCheckboxes" hide-details></v-checkbox>
          </v-col>
          <v-col cols="7" class="pa-0 pt-2">
            <label class="inbox-sub ml-3">{{ $t(`label.selectAll`) }}</label>
          </v-col>
          <v-col cols="2" class="pa-0">
            <v-btn elevation="0" class="icon-btn mt=0" @click="readMsg()">
              <read-icon></read-icon>
              <span class="inbox-sub ml-3">{{ $t(`label.markRead`) }}</span>
            </v-btn>
          </v-col>
          <v-col cols="2" class="pa-0 text-center">
            <v-btn elevation="0" class="icon-btn mt=0" @click="deleteMsg()">
              <delete-icon-2></delete-icon-2>
              <span class="inbox-sub ml-3">{{ $t(`label.delete`) }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="selectedTab == 'announcement'" no-gutters justify="start" class="pa-4"
               :class="$vuetify.breakpoint.mdAndDown ? 'px-8' : 'pt-1'">
          <v-col cols="12" v-for="(a, index) in selectedList" :key="a.id"
                 :class="a.has_read ? 'inbox-message-isread' : ''">
            <!--mobile view notifaction-->
            <v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters class="py-1 ">
              <v-col cols="12" md="3" lg="2">
                <v-checkbox class="mt-0" v-bind:label="a.send_at | shortDate" color="primary" v-model="a.selected"
                            hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="9" lg="10">
                <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'ml-8' : ''" @click="readInboxMsg(a.id)">
                  <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'my-3' : 'mb-3'">
                    <label class="font-weight-bold subtitle-1">{{ a.subject }}</label>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ a.message }}</label>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--desktop view notifaction-->
            <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters class="py-1 inbox-content">
              <v-col cols="1">
                <v-checkbox class="mt-0" v-model="a.selected" hide-details></v-checkbox>
              </v-col>
              <v-col cols="8">
                <v-row no-gutters @click="readInboxMsg(a.id)">
                  <v-col cols="12" class="mb-3">
                    <label class="font-weight-bold">{{ a.subject }}</label>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="text-right pr-4">
                <label class="mt-0 font-weight">{{ a.send_at | shortDate }}</label>
              </v-col>
              <v-col cols="1">
                <label class="mt-0 font-weight">{{ a.send_at | shortTime }}</label>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters class="py-3">
              <v-col cols="12">
                <v-divider v-if="index !== announcementList.length - 1"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-1 pb-3 text-center" v-if="announcementList.length == 0">
            <label class="field-name">{{ $t(`message.noMeassage`) }}</label>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="selectedTab == 'inbox'" no-gutters align="start" justify="start" class="pa-4"
               :class="$vuetify.breakpoint.mdAndDown ? 'px-8' : 'pt-1'">
          <v-col cols="12" v-for="(a, index) in selectedList" :key="a.id"
                 :class="a.has_read ? 'inbox-message-isread' : ''">
            <!--mobile view inbox-->
            <v-row v-if="$vuetify.breakpoint.mdAndDown" class="py-1 o-gutters">
              <v-col cols="12" md="3" lg="2">
                <v-checkbox v-model="a.selected" class="mt-0" v-bind:label="a.send_at | shortDate" color="primary"
                            hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" md="9" lg="10" @click="openMsgDialog(a)">
                <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'ml-8' : ''">
                  <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'my-3' : 'mb-3'">
                    <label class="font-weight-bold subtitle-1">{{ a.subject }}</label>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ a.message }}</label>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--desktop view inbox-->
            <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters class="py-1 inbox-content">
              <v-col cols="1">
                <v-checkbox class="mt-0" v-model="a.selected" hide-details></v-checkbox>
              </v-col>
              <v-col cols="8">
                <v-row no-gutters @click="openMsgDialogs(a)">
                  <v-col cols="12" class="mb-3">
                    <label class="font-weight-bold">{{ a.subject }}</label>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="text-right pr-4">
                <label class="mt-0 font-weight">{{ a.send_at | shortDate }}</label>
              </v-col>
              <v-col cols="1">
                <label class="mt-0 font-weight">{{ a.send_at | shortTime }}</label>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters class="py-3">
              <v-col cols="12">
                <v-divider v-if="index !== inboxList.length - 1"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-1 pb-3 text-center" v-if="inboxList.length == 0">
            <label class="field-name">{{ $t(`message.noMeassage`) }}</label>
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.mdAndDown">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'mb-6' : ''" v-if="pagination.totalPage > 1">
          <v-col cols="12">
            <v-pagination
              light
              circle
              class="vip_summary_pagination"
              v-model="pagination.currentPage"
              :length="pagination.totalPage"
              @input="changePage"
              :next-icon="'arrow_forward_ios'"
              :prev-icon="'arrow_back_ios'"
              :total-visible="pagination.paginationPageLimit"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <app-dialog :dialogShow="msgDialog.show" :max-width="1100" :title="msgDialog.title" :subtitle="msgDialog.subtitle"
                :closeAction="this.closeMsgDialog">
      <v-container
        :class="[$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5', $vuetify.breakpoint.smAndDown ? 'maxFrameHeight' : 'fill-height']">
        <div v-html="msgDialog.msg"
             :class="['inbox_msg_content' , $vuetify.breakpoint.smAndDown ? '' : 'flow-overHeight']"></div>
      </v-container>
    </app-dialog>
    <newapp-dialog :dialogShow="msgDialogs.show" :title="msgDialogs.title" :subtitle="msgDialogs.subtitle"
                   :max-width="1100" :closeAction="this.closeMsgDialogs" custom-class="pa-6">
      <v-container
        :class="[$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5', $vuetify.breakpoint.smAndDown ? 'maxFrameHeight' : 'fill-height']">
        <div v-html="msgDialogs.msg"
             :class="['inbox_msg_content' , $vuetify.breakpoint.smAndDown ? '' : 'flow-overHeight']"></div>
      </v-container>
    </newapp-dialog>
  </div>
</template>
<script>
import { ROUTE_NAME } from "@/constants/route.constants";
import { uiHelper, locale, errorCodeHelper, formValidator } from "@/util";
import { SHARED } from "@/constants/constants";
import deleteIcon from "@/assets/svg/delete-icon.svg";
import readIcon from "@/assets/svg/read-icon.svg";
import deleteIcon2 from "@/assets/svg/delete-icon-2.svg";

import _ from "lodash";
import { INBOX_GET_MSG, INBOX_READ_MSG, INBOX_DELETE_MSG, INBOX_READ_BULK_MSG } from "@/store/inbox.module";
import NewappDialog from "@/components/newDialog.vue";

export default {
  name: "myInbox",
  components: {
    NewappDialog,
    deleteIcon,
    deleteIcon2,
    readIcon
  },
  data: () => ({
    language: uiHelper.getLanguage(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: "inbox",
    msgDialog: {
      show: false
    },
    msgDialogs: {
      show: false
    },
    selectAll: false,
    inboxList: [],
    announcementList: [],
    selectedList: [],
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    searchCriteria: {
      pageNumber: 1,
      pageSize: 10,
      mobilePageSize: 10
    }
  }),
  created() {
    this.getInboxMsg();
  },
  computed: {
    msgRes() {
      return this.$store.state.inbox.inboxMsg;
    }
  },
  watch: {
    msgRes() {
      this.getFilterAnnoucementList();
      this.getFilterInboxList();
      this.onChangeTab(this.selectedTab);
    }
  },
  methods: {
    selectAllCheckboxes() {
      this.selectedList.forEach(item => {
        item.selected = this.selectAll;
      });
    },
    getFilterAnnoucementList() {
      let self = this;
      this.announcementList = _.cloneDeep(
        this.$store.state.inbox.inboxMsg.filter(a => {
          self.$options.filters.shortDate(a.sent_at);
          return a.category == "2";
        })
      );
    },
    getFilterInboxList() {
      let self = this;
      this.inboxList = _.cloneDeep(
        this.$store.state.inbox.inboxMsg.filter(a => {
          self.$options.filters.shortDate(a.sent_at);
          return a.category == "1" || a.category == "3" || a.category == "5";
        })
      );
    },
    getInboxMsg() {
      this.$store.dispatch(`${INBOX_GET_MSG}`);
    },
    readInboxMsg(msgId) {
      let obj = {
        msgId: msgId
      };
      this.$store.dispatch(`${INBOX_READ_MSG}`, { obj });
      this.getInboxMsg();
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.content,
        title: selectedMsg.subject,
        subtitle: selectedMsg.message
      };
      this.readInboxMsg(selectedMsg.id);
    },
    openMsgDialogs(selectedMsg) {
      this.msgDialogs = {
        show: true,
        msg: selectedMsg.content,
        title: selectedMsg.subject,
        subtitle: selectedMsg.message
      };
      this.readInboxMsg(selectedMsg.id);
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      };
    },
    closeMsgDialogs() {
      this.msgDialogs = {
        show: false
      };
    },
    onChangeTab(tab) {
      this.selectedTab = tab;
      if (tab == "inbox") {
        this.renderPage(this.inboxList);
      } else {
        this.renderPage(this.announcementList);
      }
    },
    renderPage(list) {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.pagination.totalPage = Math.ceil(list.length / this.searchCriteria.mobilePageSize);
      } else {
        this.pagination.totalPage = Math.ceil(list.length / this.searchCriteria.pageSize);
      }
      this.searchCriteria.pageNumber = 1;
      this.changePage(1);
    },
    changePage(targetPage) {
      let list = [];
      if (this.selectedTab == "inbox") {
        list = this.inboxList;
      } else {
        list = this.announcementList;
      }
      this.searchCriteria.pageNumber = targetPage;
      if (this.$vuetify.breakpoint.xsOnly) {
        this.selectedList = list.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize, this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize);
      } else {
        this.selectedList = list.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize);
      }
    },
    async deleteMsg() {
      let list = _.map(
        this.selectedList.filter(a => {
          return a.selected;
        }),
        "id"
      );
      if (list && list.length > 0) {
        let obj = {
          msgId: list.join(",")
        };
        await this.$store.dispatch(`${INBOX_DELETE_MSG}`, { obj });
        this.getInboxMsg();
      } else {
        this.$parent.openAlert(false, locale.getMessage(`message.deleteMsgError`));
      }
    },
    async readMsg() {
      let list = _.map(
        this.selectedList.filter(a => {
          return a.selected;
        }),
        "id"
      );
      if (list && list.length > 0) {
        let obj = {
          msgId: list.join(",")
        };
        await this.$store.dispatch(`${INBOX_READ_BULK_MSG}`, { obj });
        this.getInboxMsg();
      } else {
        this.$parent.openAlert(false, locale.getMessage(`message.readMsgError`));
      }
    }
  }
};
</script>
<style lang="scss">
.flow-overHeight {
  height: 450px;
  overflow: auto;
}

.desktop_inbox_card {
  border-radius: 12px !important;
  //height: 660px !important;
  min-height: 700px;
  font-family: 'Roboto', sans-serif;

  .inbox-desktop-txt {
    font-weight: bold;
  }
}

.inbox-card {
  overflow-x: hidden;
  overflow-y: hidden;

  .inbox-message-isread label {
    color: var(--v-title_color2-base) !important;
    font-weight: normal !important;
  }
}

.inbox-content {
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif;
}

.inbox-tab {
  border-radius: 12px;
  width: 150px;
  margin-right: 16px;
  padding: 10px;
  background-color: #fff;
  text-transform: capitalize;
}

.font-weight {
  font-weight: bold;
}

.inbox-details-header {
  background-color: #f3f5f6;
  border-radius: 12px;
}

.inbox-sub {
  font-family: 'Roboto', sans-serif;
  color: #6c6c6c !important;
  text-transform: none !important;
  font-size: 14px;
}

.tab-btn {
  border-style: solid !important;
  border-color: #ffce01 !important;
}

.selected-tab {
  border-radius: 12px;
  width: 150px;
  margin-right: 16px;
  padding: 10px;
  background-color: #ffce01 !important;
  text-transform: capitalize;
}

.not-selected-tab {
  border-radius: 12px;
  width: 150px;
  margin-right: 16px;
  padding: 10px;
  color: #6c6c6c !important;
  border-color: #ffce01;
  background-color: #fff !important;
  text-transform: capitalize;
}

.inbox-tab-mobile {
  width: 120px;
  margin-right: 10px;
  padding: 5px;
  background-color: #fff;
  text-transform: capitalize;
}

.inbox_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.inbox_msg_content {
  font-family: 'Heebo-Medium';
  font-size: 14px;
  text-align: left;
  max-width: 100%;
  text-transform: none !important;
}

.inbox_msg_content img {
  width: 300px;
}

.vip_summary_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 12px;
    }
  }
}

@media (max-width: 959px) {
  .inbox-card {
    height: 80%;
    overflow-x: hidden;
    overflow-y: hidden;

    .inbox-message-isread label {
      color: var(--v-title_color2-base) !important;
    }
  }
}
</style>
